import * as React from "react";
import HeroHome from "../components/hero-home";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import Team from "../components/team";
import Tours from "../components/tours";

const TeamPage = () => {
  return (
    <Layout>
      <h2 className="text-3xl font-bold text-left mb-4">Team</h2>
      <Team />
    </Layout>
  );
};

export default TeamPage;

export const Head = () => <title>Team</title>;
